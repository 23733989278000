//  Variables

$nav-item-icon-size:              28px;
$nav-item-header-color:           $gray-700;
$nav-item-header-font-size:       $font-size-sm;

$nav-item-link-bg:                transparent;
$nav-item-link-color:             $gray-800;

$nav-item-link-active-bg:         $gray-200;
$nav-item-link-active-color:      $primary;
