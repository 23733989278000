// Variables

// General

$sidebar-width:             260px;
$app-sidebar-transition:    cubic-bezier(0.685, 0.0473, 0.346, 1);
// Dark color scheme

$sidebar-bg:                $gray-900;
$sidebar-shadow:            $box-shadow-layout-right;
$sidebar-content-bg:        transparent;
